import { Text, TouchableOpacity, View } from "react-native";
import React, { useEffect, useState } from "react";
import tailwind, { style as tw } from "twrnc";
import { normalize } from "components/normalize";
import { useHistory } from "react-router-dom";
import { Entypo } from "@expo/vector-icons";

const PracticeVowels = () => {
  const history = useHistory();
  const letters = [
    {
      name: "Alap",
      letter: "ܐ",
      sounds: [
        { vowel: "Zqapa", sound: "ah", mark: "ܐܵ" },
        { vowel: "Ptakha", sound: "uh", mark: "ܐܲ" },
        { vowel: "Zlama Yareekha", sound: "eh", mark: "ܐܹ" },
        { vowel: "Zlama Kirya", sound: "ih", mark: "ܐܸ" },
        { vowel: "Waw Rwakha", sound: "oh", mark: "ܐܘܿ" },
        { vowel: "Waw Rwasa", sound: "oo", mark: "ܐܘ̣" },
        { vowel: "Yodh Khwasa", sound: "ee", mark: "ܐܝܼ" },
      ],
    },
    {
      name: "Bet",
      letter: "ܒ",
      sounds: [
        { vowel: "Zqapa", sound: "bah", mark: "ܒܵ" },
        { vowel: "Ptakha", sound: "buh", mark: "ܒܲ" },
        { vowel: "Zlama Yareekha", sound: "beh", mark: "ܒܹ" },
        { vowel: "Zlama Kirya", sound: "bih", mark: "ܒܸ" },
        { vowel: "Waw Rwakha", sound: "boh", mark: "ܒܘܿ" },
        { vowel: "Waw Rwasa", sound: "boo", mark: "ܒܘ̣" },
        { vowel: "Yodh Khwasa", sound: "bee", mark: "ܒܝܼ" },
      ],
    },
    {
      name: "Gamal",
      letter: "ܓ",
      sounds: [
        { vowel: "Zqapa", sound: "gah", mark: "ܓܵ" },
        { vowel: "Ptakha", sound: "guh", mark: "ܓܲ" },
        { vowel: "Zlama Yareekha", sound: "geh", mark: "ܓܹ" },
        { vowel: "Zlama Kirya", sound: "gih", mark: "ܓܸ" },
        { vowel: "Waw Rwakha", sound: "goh", mark: "ܓܘܿ" },
        { vowel: "Waw Rwasa", sound: "goo", mark: "ܓܘ̣" },
        { vowel: "Yodh Khwasa", sound: "gee", mark: "ܓܝܼ" },
      ],
    },
    {
      name: "Dalat",
      letter: "ܕ",
      sounds: [
        { vowel: "Zqapa", sound: "dah", mark: "ܕܵ" },
        { vowel: "Ptakha", sound: "duh", mark: "ܕܲ" },
        { vowel: "Zlama Yareekha", sound: "deh", mark: "ܕܹ" },
        { vowel: "Zlama Kirya", sound: "dih", mark: "ܕܸ" },
        { vowel: "Waw Rwakha", sound: "doh", mark: "ܕܘܿ" },
        { vowel: "Waw Rwasa", sound: "doo", mark: "ܕܘ̣" },
        { vowel: "Yodh Khwasa", sound: "dee", mark: "ܕܝܼ" },
      ],
    },
    {
      name: "Heh",
      letter: "ܗ",
      sounds: [
        { vowel: "Zqapa", sound: "hah", mark: "ܗܵ" },
        { vowel: "Ptakha", sound: "huh", mark: "ܗܲ" },
        { vowel: "Zlama Yareekha", sound: "heh", mark: "ܗܹ" },
        { vowel: "Zlama Kirya", sound: "hih", mark: "ܗܸ" },
        { vowel: "Waw Rwakha", sound: "hoh", mark: "ܗܘܿ" },
        { vowel: "Waw Rwasa", sound: "hoo", mark: "ܗܘ̣" },
        { vowel: "Yodh Khwasa", sound: "hee", mark: "ܗܝܼ" },
      ],
    },
    {
      name: "Waw",
      letter: "ܘ",
      sounds: [
        { vowel: "Zqapa", sound: "wah", mark: "ܘܵ" },
        { vowel: "Ptakha", sound: "wuh", mark: "ܘܲ" },
        { vowel: "Zlama Yareekha", sound: "weh", mark: "ܘܹ" },
        { vowel: "Zlama Kirya", sound: "wih", mark: "ܘܸ" },
        { vowel: "Waw Rwakha", sound: "woh", mark: "ܘܘܿ" },
        { vowel: "Waw Rwasa", sound: "woo", mark: "ܘܘ̣" },
        { vowel: "Yodh Khwasa", sound: "wee", mark: "ܘܝܼ" },
      ],
    },
    {
      name: "Zain",
      letter: "ܙ",
      sounds: [
        { vowel: "Zqapa", sound: "zah", mark: "ܙܵ" },
        { vowel: "Ptakha", sound: "zuh", mark: "ܙܲ" },
        { vowel: "Zlama Yareekha", sound: "zeh", mark: "ܙܹ" },
        { vowel: "Zlama Kirya", sound: "zih", mark: "ܙܸ" },
        { vowel: "Waw Rwakha", sound: "zoh", mark: "ܙܘܿ" },
        { vowel: "Waw Rwasa", sound: "zoo", mark: "ܙܘ̣" },
        { vowel: "Yodh Khwasa", sound: "zee", mark: "ܙܝܼ" },
      ],
    },
    {
      name: "Khet",
      letter: "ܚ",
      sounds: [
        { vowel: "Zqapa", sound: "khah", mark: "ܚܵ" },
        { vowel: "Ptakha", sound: "khuh", mark: "ܚܲ" },
        { vowel: "Zlama Yareekha", sound: "kheh", mark: "ܚܹ" },
        { vowel: "Zlama Kirya", sound: "khih", mark: "ܚܸ" },
        { vowel: "Waw Rwakha", sound: "khoh", mark: "ܚܘܿ" },
        { vowel: "Waw Rwasa", sound: "khoo", mark: "ܚܘ̣" },
        { vowel: "Yodh Khwasa", sound: "khee", mark: "ܚܝܼ" },
      ],
    },
    {
      name: "Teth",
      letter: "ܛ",
      sounds: [
        { vowel: "Zqapa", sound: "tah", mark: "ܛܵ" },
        { vowel: "Ptakha", sound: "tuh", mark: "ܛܲ" },
        { vowel: "Zlama Yareekha", sound: "teh", mark: "ܛܹ" },
        { vowel: "Zlama Kirya", sound: "tih", mark: "ܛܸ" },
        { vowel: "Waw Rwakha", sound: "toh", mark: "ܛܘܿ" },
        { vowel: "Waw Rwasa", sound: "too", mark: "ܛܘ̣" },
        { vowel: "Yodh Khwasa", sound: "tee", mark: "ܛܝܼ" },
      ],
    },
    {
      name: "Yodh",
      letter: "ܝ",
      sounds: [
        { vowel: "Zqapa", sound: "yah", mark: "ܝܵ" },
        { vowel: "Ptakha", sound: "yuh", mark: "ܝܲ" },
        { vowel: "Zlama Yareekha", sound: "yeh", mark: "ܝܹ" },
        { vowel: "Zlama Kirya", sound: "yih", mark: "ܝܸ" },
        { vowel: "Waw Rwakha", sound: "yoh", mark: "ܝܘܿ" },
        { vowel: "Waw Rwasa", sound: "yoo", mark: "ܝܘ̣" },
        { vowel: "Yodh Khwasa", sound: "yee", mark: "ܝܝܼ" },
      ],
    },
    {
      name: "Kap",
      letter: "ܟ",
      sounds: [
        { vowel: "Zqapa", sound: "kah", mark: "ܟܵ" },
        { vowel: "Ptakha", sound: "kuh", mark: "ܟܲ" },
        { vowel: "Zlama Yareekha", sound: "keh", mark: "ܟܹ" },
        { vowel: "Zlama Kirya", sound: "kih", mark: "ܟܸ" },
        { vowel: "Waw Rwakha", sound: "koh", mark: "ܟܘܿ" },
        { vowel: "Waw Rwasa", sound: "koo", mark: "ܟܘ̣" },
        { vowel: "Yodh Khwasa", sound: "kee", mark: "ܟܝܼ" },
      ],
    },
    {
      name: "Lamadh",
      letter: "ܠ",
      sounds: [
        { vowel: "Zqapa", sound: "lah", mark: "ܠܵ" },
        { vowel: "Ptakha", sound: "luh", mark: "ܠܲ" },
        { vowel: "Zlama Yareekha", sound: "leh", mark: "ܠܹ" },
        { vowel: "Zlama Kirya", sound: "lih", mark: "ܠܸ" },
        { vowel: "Waw Rwakha", sound: "loh", mark: "ܠܘܿ" },
        { vowel: "Waw Rwasa", sound: "loo", mark: "ܠܘ̣" },
        { vowel: "Yodh Khwasa", sound: "lee", mark: "ܠܝܼ" },
      ],
    },
    {
      name: "Meem",
      letter: "ܡ",
      sounds: [
        { vowel: "Zqapa", sound: "mah", mark: "ܡܵ" },
        { vowel: "Ptakha", sound: "muh", mark: "ܡܲ" },
        { vowel: "Zlama Yareekha", sound: "meh", mark: "ܡܹ" },
        { vowel: "Zlama Kirya", sound: "mih", mark: "ܡܸ" },
        { vowel: "Waw Rwakha", sound: "moh", mark: "ܡܘܿ" },
        { vowel: "Waw Rwasa", sound: "moo", mark: "ܡܘ̣" },
        { vowel: "Yodh Khwasa", sound: "mee", mark: "ܡܝܼ" },
      ],
    },
    {
      name: "Noon",
      letter: "ܢ",
      sounds: [
        { vowel: "Zqapa", sound: "nah", mark: "ܢܵ" },
        { vowel: "Ptakha", sound: "nuh", mark: "ܢܲ" },
        { vowel: "Zlama Yareekha", sound: "neh", mark: "ܢܹ" },
        { vowel: "Zlama Kirya", sound: "nih", mark: "ܢܸ" },
        { vowel: "Waw Rwakha", sound: "noh", mark: "ܢܘܿ" },
        { vowel: "Waw Rwasa", sound: "noo", mark: "ܢܘ̣" },
        { vowel: "Yodh Khwasa", sound: "nee", mark: "ܢܝܼ" },
      ],
    },
    {
      name: "Simkat",
      letter: "ܣ",
      sounds: [
        { vowel: "Zqapa", sound: "sah", mark: "ܣܵ" },
        { vowel: "Ptakha", sound: "suh", mark: "ܣܲ" },
        { vowel: "Zlama Yareekha", sound: "seh", mark: "ܣܹ" },
        { vowel: "Zlama Kirya", sound: "sih", mark: "ܣܸ" },
        { vowel: "Waw Rwakha", sound: "soh", mark: "ܣܘܿ" },
        { vowel: "Waw Rwasa", sound: "soo", mark: "ܣܘ̣" },
        { vowel: "Yodh Khwasa", sound: "see", mark: "ܣܝܼ" },
      ],
    },
    {
      name: "Aih",
      letter: "ܥ",
      sounds: [
        { vowel: "Zqapa", sound: "ah", mark: "ܥܵ" },
        { vowel: "Ptakha", sound: "uh", mark: "ܥܲ" },
        { vowel: "Zlama Yareekha", sound: "eh", mark: "ܥܹ" },
        { vowel: "Zlama Kirya", sound: "ih", mark: "ܥܸ" },
        { vowel: "Waw Rwakha", sound: "oh", mark: "ܥܘܿ" },
        { vowel: "Waw Rwasa", sound: "oo", mark: "ܥܘ̣" },
        { vowel: "Yodh Khwasa", sound: "ee", mark: "ܥܝܼ" },
      ],
    },
    {
      name: "Peh",
      letter: "ܦ",
      sounds: [
        { vowel: "Zqapa", sound: "pah", mark: "ܦܵ" },
        { vowel: "Ptakha", sound: "puh", mark: "ܦܲ" },
        { vowel: "Zlama Yareekha", sound: "peh", mark: "ܦܹ" },
        { vowel: "Zlama Kirya", sound: "pih", mark: "ܦܸ" },
        { vowel: "Waw Rwakha", sound: "poh", mark: "ܦܘܿ" },
        { vowel: "Waw Rwasa", sound: "poo", mark: "ܦܘ̣" },
        { vowel: "Yodh Khwasa", sound: "pee", mark: "ܦܝܼ" },
      ],
    },
    {
      name: "Sadeh",
      letter: "ܨ",
      sounds: [
        { vowel: "Zqapa", sound: "sah", mark: "ܨܵ" },
        { vowel: "Ptakha", sound: "suh", mark: "ܨܲ" },
        { vowel: "Zlama Yareekha", sound: "seh", mark: "ܨܹ" },
        { vowel: "Zlama Kirya", sound: "sih", mark: "ܨܸ" },
        { vowel: "Waw Rwakha", sound: "soh", mark: "ܨܘܿ" },
        { vowel: "Waw Rwasa", sound: "soo", mark: "ܨܘ̣" },
        { vowel: "Yodh Khwasa", sound: "see", mark: "ܨܝܼ" },
      ],
    },
    {
      name: "Qop",
      letter: "ܩ",
      sounds: [
        { vowel: "Zqapa", sound: "qah", mark: "ܩܵ" },
        { vowel: "Ptakha", sound: "quh", mark: "ܩܲ" },
        { vowel: "Zlama Yareekha", sound: "qeh", mark: "ܩܹ" },
        { vowel: "Zlama Kirya", sound: "qih", mark: "ܩܸ" },
        { vowel: "Waw Rwakha", sound: "qoh", mark: "ܩܘܿ" },
        { vowel: "Waw Rwasa", sound: "qoo", mark: "ܩܘ̣" },
        { vowel: "Yodh Khwasa", sound: "qee", mark: "ܩܝܼ" },
      ],
    },
    {
      name: "Resh",
      letter: "ܪ",
      sounds: [
        { vowel: "Zqapa", sound: "rah", mark: "ܪܵ" },
        { vowel: "Ptakha", sound: "ruh", mark: "ܪܲ" },
        { vowel: "Zlama Yareekha", sound: "reh", mark: "ܪܹ" },
        { vowel: "Zlama Kirya", sound: "rih", mark: "ܪܸ" },
        { vowel: "Waw Rwakha", sound: "roh", mark: "ܪܘܿ" },
        { vowel: "Waw Rwasa", sound: "roo", mark: "ܪܘ̣" },
        { vowel: "Yodh Khwasa", sound: "ree", mark: "ܪܝܼ" },
      ],
    },
    {
      name: "Sheen",
      letter: "ܫ",
      sounds: [
        { vowel: "Zqapa", sound: "shah", mark: "ܫܵ" },
        { vowel: "Ptakha", sound: "shuh", mark: "ܫܲ" },
        { vowel: "Zlama Yareekha", sound: "sheh", mark: "ܫܹ" },
        { vowel: "Zlama Kirya", sound: "shih", mark: "ܫܸ" },
        { vowel: "Waw Rwakha", sound: "shoh", mark: "ܫܘܿ" },
        { vowel: "Waw Rwasa", sound: "shoo", mark: "ܫܘ̣" },
        { vowel: "Yodh Khwasa", sound: "shee", mark: "ܫܝܼ" },
      ],
    },
    {
      name: "Taw",
      letter: "ܬ",
      sounds: [
        { vowel: "Zqapa", sound: "tah", mark: "ܬܵ" },
        { vowel: "Ptakha", sound: "tuh", mark: "ܬܲ" },
        { vowel: "Zlama Yareekha", sound: "teh", mark: "ܬܹ" },
        { vowel: "Zlama Kirya", sound: "tih", mark: "ܬܸ" },
        { vowel: "Waw Rwakha", sound: "toh", mark: "ܬܘܿ" },
        { vowel: "Waw Rwasa", sound: "too", mark: "ܬܘ̣" },
        { vowel: "Yodh Khwasa", sound: "tee", mark: "ܬܝܼ" },
      ],
    },
  ];

  const [randomTestSet, setRandomTestSet] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showCorrectAnswer, setshowCorrectAnswer] = useState(false);
  const [wrongLetterChosenIndex, setwrongLetterChosenIndex] = useState(null);

  const Start = () => {
    // const newRandomTestSet = [];

    // while (newRandomTestSet.length < 25) {
    //   const randomLetter = letters[Math.floor(Math.random() * letters.length)];
    //   const doesExist = newRandomTestSet.some(
    //     (letter) => letter.letterObj.name === randomLetter.name
    //   );
    //   if (!doesExist) {
    //     const randomVowel =
    //       randomLetter.sounds[
    //         Math.floor(Math.random() * randomLetter.sounds.length)
    //       ];
    //     // newRandomTestSet.push({ letter: randomLetter, vowel: randomVowel });
    //     const randomOptions = [randomVowel.sound];
    //     while (randomOptions.length < 4) {
    //       const randomIndex = Math.floor(
    //         Math.random() * randomLetter.sounds.length
    //       );
    //       const doesExist = randomOptions.some(
    //         (option) => option === randomLetter.sounds[randomIndex].sound
    //       );
    //       if (!doesExist) {
    //         randomOptions.push(randomLetter.sounds[randomIndex].sound);
    //       }
    //     }
    //     newRandomTestSet.push({
    //       letterObj: randomLetter,
    //       letter: randomVowel.mark,
    //       answer: randomVowel.sound,
    //       randomOptions: randomOptions.sort(() => Math.random() - 0.5),
    //     });
    //   }
    // }
    // setRandomTestSet(newRandomTestSet);
    const newRandomTestSet = [];

    while (newRandomTestSet.length < 25) {
      const randomLetter = letters[Math.floor(Math.random() * letters.length)];
      const randomVowel =
        randomLetter.sounds[
          Math.floor(Math.random() * randomLetter.sounds.length)
        ];
      const randomOptions = [randomVowel.sound];
      while (randomOptions.length < 4) {
        const randomIndex = Math.floor(
          Math.random() * randomLetter.sounds.length
        );
        const doesExist = randomOptions.some(
          (option) => option === randomLetter.sounds[randomIndex].sound
        );
        if (!doesExist) {
          randomOptions.push(randomLetter.sounds[randomIndex].sound);
        }
      }
      newRandomTestSet.push({
        letterObj: randomLetter,
        letter: randomVowel.mark,
        answer: randomVowel.sound,
        randomOptions: randomOptions.sort(() => Math.random() - 0.5),
      });
    }
    setRandomTestSet(newRandomTestSet);
  };

  const CheckAnswer = (answer) => {
    setwrongLetterChosenIndex(null);
    setshowCorrectAnswer(false);
    if (answer === randomTestSet[currentQuestion].answer) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setwrongLetterChosenIndex(
        randomTestSet[currentQuestion].randomOptions.indexOf(answer)
      );
      setshowCorrectAnswer(true);
    }
  };

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={[
          {
            padding: 20,
            justifyContent: "center",
            alignItems: "center",
          },
          tailwind.style([
            "shadow-lg",
            "rounded-lg",
            "bg-slate-300",
            "w-10/12",
            "h-11/12",
          ]),
        ]}
      >
        {currentQuestion === randomTestSet.length &&
        randomTestSet.length > 0 ? (
          <>
            <Text
              style={[
                tailwind.style([
                  "text-black",
                  "font-bold",
                  "mt-10",
                  "mb-5",
                  "text-center",
                ]),
                { fontSize: normalize(26) },
              ]}
            >
              Congratulations! You have completed the game
            </Text>
            <TouchableOpacity
              style={tailwind.style("bg-blue-500 rounded-lg p-4 mb-4")}
              onPress={() => {
                setCurrentQuestion(0);
                Start();
              }}
            >
              <Text style={tailwind.style("text-white text-lg text-center")}>
                Restart
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={tailwind.style("bg-blue-500 rounded-lg p-4")}
              onPress={() => history.push("/learn-vowels-home")}
            >
              <Text style={tailwind.style("text-white text-lg text-center")}>
                Go Back
              </Text>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <TouchableOpacity
              onPress={() => history.push("/learn-vowels-home")}
              style={tailwind.style("absolute top-5 left-7")}
            >
              <Entypo name="chevron-with-circle-left" size={35} color="black" />
            </TouchableOpacity>
            {randomTestSet.length > 0 ? (
              <>
                <Text
                  style={[
                    tailwind.style([
                      "text-black",
                      "font-bold",
                      "absolute",
                      "top-7",
                      "right-7",
                    ]),
                    { fontSize: normalize(22) },
                  ]}
                >
                  Score: {currentQuestion + 1}/{randomTestSet.length}
                </Text>
                <Text
                  style={[
                    tailwind.style(["text-black", "font-bold"]),
                    {
                      fontSize: normalize(26),
                      marginTop: normalize(50),
                      marginBottom: normalize(20),
                    },
                  ]}
                >
                  What is the sound of this?
                </Text>
                <Text
                  style={[
                    tailwind.style(["text-black"]),
                    {
                      fontSize: normalize(80),
                      marginTop: normalize(50),
                      marginBottom: normalize(20),
                      fontFamily: "AssyrianFont",
                    },
                  ]}
                >
                  {randomTestSet[currentQuestion].letter}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    marginTop: 25,
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {randomTestSet[currentQuestion].randomOptions.map(
                    (option, index) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={() => CheckAnswer(option)}
                          style={[
                            {
                              backgroundColor: "white",
                              padding: 10,
                              margin: 12,
                              borderRadius: 5,
                              justifyContent: "center",
                              alignItems: "center",
                              width: 90,
                            },
                            tailwind.style([
                              "shadow-md",
                              "hover:shadow-lg",
                              "hover:bg-slate-400",
                            ]),
                            showCorrectAnswer &&
                              option ===
                                randomTestSet[currentQuestion].answer && {
                                backgroundColor: "green",
                              },
                            showCorrectAnswer &&
                              index === wrongLetterChosenIndex && {
                                backgroundColor: "red",
                              },
                          ]}
                        >
                          <Text style={tw("text-black text-lg text-center")}>
                            {option}
                          </Text>
                        </TouchableOpacity>
                      );
                    }
                  )}
                </View>
              </>
            ) : (
              <>
                <Text
                  style={tailwind.style([
                    "text-2xl",
                    "font-bold",
                    "text-black",
                    "text-center",
                  ])}
                >
                  Practice Vowels
                </Text>
                <TouchableOpacity
                  style={tw(
                    "bg-blue-500 rounded-lg justify-center items-center p-4 mt-10"
                  )}
                  onPress={Start}
                >
                  <Text style={tw("text-white text-lg text-center")}>
                    Start
                  </Text>
                </TouchableOpacity>
              </>
            )}
          </>
        )}
      </View>
    </View>
  );
};

export default PracticeVowels;
