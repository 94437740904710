import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomeScreen from 'screens/non-authed/HomeScreen';
import PracticeLetterFlashcards from 'screens/non-authed/LetterFlashCardGame/PracticeLetterFlashcards';
import NotFound from 'screens/non-authed/NotFound';
import OrderOfLettersGame from 'screens/non-authed/OrderOfLettersGame/Index';
import OurFatherGame from 'screens/non-authed/OurFatherPrayerPractice/Index';
import LearnVowelsHome from 'screens/non-authed/LearnVowels/Index';
import LearnVowels from 'screens/non-authed/LearnVowels/LearnVowels';
import PracticeVowels from 'screens/non-authed/LearnVowels/PracticeVowels';
import LearnSpecialLetters from 'screens/non-authed/LearnVowels/LearnSpecialLetters';
import PracticeSpecialLetters from 'screens/non-authed/LearnVowels/PracticeSpecialLetters';
import WriteAssyrianTextInput from 'screens/non-authed/LearnVowels/WriteAssyrianTextInput';

const NonAuthRoute = () => {
    return (
        <Switch>
            <Route exact path="/" component={HomeScreen} />
            <Route path="/practice-letter-flashcards" component={PracticeLetterFlashcards} />
            <Route path="/order-of-alap-bet-practice" component={OrderOfLettersGame} />
            <Route path="/our-father-practice" component={OurFatherGame} />
            <Route path="/learn-vowels-home" component={LearnVowelsHome} />
            <Route path="/learn-vowels" component={LearnVowels} />
            <Route path="/practice-vowels" component={PracticeVowels} />
            <Route path="/learn-special-letters" component={LearnSpecialLetters} />
            <Route path="/practice-special-letters" component={PracticeSpecialLetters} />
            <Route path="/write-assyrian-text-input" component={WriteAssyrianTextInput} />
            <Route path="*" component={NotFound} />
        </Switch>
    )
}

export default NonAuthRoute