import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Modal,
} from "react-native";
import React, { useEffect, useState } from "react";
import tailwind, { style as tw } from "twrnc";
import { db } from "state/firebaseConfig";
import { normalize } from "components/normalize";
import LeaderboardModal from "./LeaderboardModal";
import { Entypo } from "@expo/vector-icons";
import { useHistory } from "react-router-dom";

const PracticeLetterFlashcards = () => {
  const letters = [
    {
      letter: "Alap",
      image: require("assets/letters/alap.png"),
    },
    {
      letter: "Bet",
      image: require("assets/letters/bet.png"),
    },
    {
      letter: "Gamal",
      image: require("assets/letters/gamal.png"),
    },
    {
      letter: "Dalat",
      image: require("assets/letters/dalat.png"),
    },
    {
      letter: "Heh",
      image: require("assets/letters/heh.png"),
    },
    {
      letter: "Waw",
      image: require("assets/letters/waw.png"),
    },
    {
      letter: "Zain",
      image: require("assets/letters/zain.png"),
    },
    {
      letter: "Khet",
      image: require("assets/letters/khet.png"),
    },
    {
      letter: "Teth",
      image: require("assets/letters/teth.png"),
    },
    {
      letter: "Yodh",
      image: require("assets/letters/yodh.png"),
    },
    {
      letter: "Kap",
      image: require("assets/letters/kap.png"),
    },
    {
      letter: "Lamadh",
      image: require("assets/letters/lamadh.png"),
    },
    {
      letter: "Meem",
      image: require("assets/letters/meem.png"),
    },
    {
      letter: "Noon",
      image: require("assets/letters/noon.png"),
    },
    {
      letter: "Simkat",
      image: require("assets/letters/simkat.png"),
    },
    {
      letter: "Aih",
      image: require("assets/letters/aih.png"),
    },
    {
      letter: "Peh",
      image: require("assets/letters/peh.png"),
    },
    {
      letter: "Sadeh",
      image: require("assets/letters/sadeh.png"),
    },
    {
      letter: "Qop",
      image: require("assets/letters/qop.png"),
    },
    {
      letter: "Resh",
      image: require("assets/letters/resh.png"),
    },
    {
      letter: "Sheen",
      image: require("assets/letters/sheen.png"),
    },
    {
      letter: "Taw",
      image: require("assets/letters/taw.png"),
    },
  ];

  //Timer

  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [language, setlanguage] = useState("english");

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  const handleStart = () => {
    setIsRunning(true);
  };

  const handleStop = () => {
    setIsRunning(false);
  };

  const handleReset = () => {
    setSeconds(0);
    setIsRunning(false);
    ReScrambleLetters();
    setscore(0);
  };

  //

  const [score, setscore] = useState(0);
  const [scrambledLetters, setscrambledLetters] = useState([]);
  const [answerOptions, setanswerOptions] = useState([]);
  const [displayName, setdisplayName] = useState("");
  const [currentLeaderboard, setcurrentLeaderboard] = useState([]);
  const [viewLeaderboardModal, setviewLeaderboardModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    ReScrambleLetters();
  }, []); // Initialize scrambled letters on mount

  useEffect(() => {
    setanswerOptions(scrambledLetters.slice(0, 4));
  }, [scrambledLetters]); // Update answer options when scrambled letters change

  const ReScrambleLetters = () => {
    const newScrambledLetters = [...letters].sort(() => Math.random() - 0.5);
    setscrambledLetters(newScrambledLetters);

    const shuffledIndices = Array.from({ length: 4 }, (_, index) => index).sort(
      () => Math.random() - 0.5
    );
    setanswerOptions(
      shuffledIndices.map((index) => newScrambledLetters[index])
    );
  };

  const NextLetter = () => {
    setscore((prev) => {
      if (prev === scrambledLetters.length - 1) {
        if (language === "english") {
          alert("You have completed the game");
        }
        if (language === "arabic") {
          alert("لقد أكملت اللعبة");
        }
        if (language === "french") {
          alert("Vous avez terminé le jeu");
        }
        handleStop();
        db.collection("public")
          .doc("leaderboard")
          .get()
          .then((doc) => {
            if (doc.exists) {
              const localLeaderBoard = doc.data().users;

              if (localLeaderBoard.length < 3) {
                localLeaderBoard.push({ name: displayName, time: seconds });
                localLeaderBoard.sort((a, b) => a.time - b.time);

                db.collection("public")
                  .doc("leaderboard")
                  .update({
                    users: localLeaderBoard,
                  })
                  .then(() => {
                    setcurrentLeaderboard(localLeaderBoard);
                  });
              } else {
                localLeaderBoard.push({ name: displayName, time: seconds });
                localLeaderBoard.sort((a, b) => a.time - b.time);

                db.collection("public")
                  .doc("leaderboard")
                  .update({
                    users: localLeaderBoard.slice(0, 3),
                  })
                  .then(() => {
                    setcurrentLeaderboard(localLeaderBoard.slice(0, 3));
                    if (
                      localLeaderBoard[localLeaderBoard.length - 1].time <
                      seconds
                    ) {
                      alert("You have been added to the leaderboard");
                    }
                  });
              }
            } else {
              db.collection("public")
                .doc("leaderboard")
                .set({
                  users: [{ name: displayName, time: seconds }],
                })
                .then(() => {
                  setcurrentLeaderboard([{ name: displayName, time: seconds }]);
                });
            }
          });

        return prev + 1;
      } else {
        const letter1 = prev + 1;
        let letter2;
        do {
          letter2 = Math.floor(Math.random() * scrambledLetters.length);
        } while (letter2 === letter1);
        let letter3;
        do {
          letter3 = Math.floor(Math.random() * scrambledLetters.length);
        } while (letter3 === letter1 || letter3 === letter2);
        let letter4;
        do {
          letter4 = Math.floor(Math.random() * scrambledLetters.length);
        } while (
          letter4 === letter1 ||
          letter4 === letter2 ||
          letter4 === letter3
        );

        setanswerOptions(
          [
            scrambledLetters[letter1],
            scrambledLetters[letter2],
            scrambledLetters[letter3],
            scrambledLetters[letter4],
          ].sort(() => Math.random() - 0.5)
        );
        return prev + 1;
      }
    });
  };

  const CheckAnswer = (letter: string) => {
    if (letter === scrambledLetters[score].letter) {
      //   alert("Correct");
      NextLetter();
      //   ReScrambleLetters();
    } else {
      if (language === "english") {
        alert("Incorrect. Correct answer is " + scrambledLetters[score].letter);
      }
      if (language === "arabic") {
        alert("غير صحيح. الجواب الصحيح هو " + scrambledLetters[score].letter);
      }
      if (language === "french") {
        alert(
          "Incorrect. La bonne réponse est " + scrambledLetters[score].letter
        );
      }
      handleReset();
    }
  };

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {scrambledLetters.length > 0 && (
        <View
          style={[
            {
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            },
            tailwind.style([
              "shadow-lg",
              "rounded-lg",
              "bg-slate-300",
              "w-10/12",
              "h-11/12",
            ]),
          ]}
        >
          {score === scrambledLetters.length && !isRunning ? (
            <>
              <Text
                style={[
                  tailwind.style(["text-black", "font-bold", "mt-10", "mb-5"]),
                  { fontSize: normalize(26) },
                ]}
              >
                {language === "english" && "Congratulations! "}
                {language === "arabic" && "تهانينا! "}
                {language === "french" && "Félicitations! "} {displayName}
              </Text>
              <Text
                style={[
                  tailwind.style(["text-black", "font-medium", "mt-5", "mb-5"]),
                  { fontSize: normalize(22) },
                ]}
              >
                {language === "english" && "Your time:"}{" "}
                {language === "arabic" && "وقتك:"}{" "}
                {language === "french" && "Votre temps:"} {seconds}
                {language === "english" && " seconds"}
                {language === "arabic" && " ثواني"}
                {language === "french" && " secondes"}
              </Text>
              <Text
                style={tailwind.style([
                  "text-black",
                  "font-medium",
                  "text-lg",
                  "mt-5",
                  "mb-5",
                ])}
              >
                {language === "english" && "Leaderboard: "}
                {language === "arabic" && "لوحة الزعامة: "}
                {language === "french" && "Classement: "}
              </Text>
              {currentLeaderboard.map((user, index) => {
                return (
                  <Text
                    key={index}
                    style={[
                      tailwind.style([
                        "text-black",
                        "font-medium",
                        "mt-5",
                        "mb-5",
                      ]),
                      { fontSize: normalize(22) },
                    ]}
                  >
                    {index + 1}. {user.name} - {user.time}
                    {language === "english" && " seconds"}
                    {language === "arabic" && " ثواني"}
                    {language === "french" && " secondes"}
                  </Text>
                );
              })}
            </>
          ) : (
            <>
              <View
                style={tailwind.style(
                  "flex-row justify-between w-1/10 absolute top-5 left-7 items-center"
                )}
              >
                <TouchableOpacity onPress={() => history.push("/")}>
                  <Entypo
                    name="chevron-with-circle-left"
                    size={35}
                    color="black"
                  />
                </TouchableOpacity>
                <Text
                  style={[
                    tailwind.style(["text-black", "font-bold", "ml-6"]),
                    { fontSize: normalize(22) },
                  ]}
                >
                  {score}/{scrambledLetters.length}
                </Text>
              </View>
              <Text
                style={[
                  tailwind.style([
                    "text-black",
                    "font-bold",
                    "absolute",
                    "top-7",
                    "right-7",
                  ]),
                  { fontSize: normalize(22) },
                ]}
              >
                  {seconds}
                  {language === "english" && " sec"}
                  {language === "arabic" && " ثانية"}
                  {language === "french" && " sec"}
              </Text>
              <Text
                style={[
                  tailwind.style(["text-black", "font-bold"]),
                  {
                    fontSize: normalize(26),
                    marginTop: normalize(50),
                    marginBottom: normalize(20),
                  },
                ]}
              >
                {language === "english" && "What Letter Is This?"}
                {language === "arabic" && "ما هو الحرف؟"}
                {language === "french" && "Quelle lettre est-ce?"}
              </Text>
              <Image
                source={
                  scrambledLetters[
                    score === scrambledLetters.length
                      ? scrambledLetters.length - 1
                      : score
                  ].image
                }
                style={{ width: 100, height: 100 }}
              />
              <Text
                style={[
                  tailwind.style(["text-black", "font-medium"]),
                  {
                    fontSize: normalize(22),
                    marginTop: normalize(20),
                    marginBottom: normalize(20),
                  },
                ]}
              >
                {language === "english" && "Choose the correct letter"}
                {language === "arabic" && "اختر الحرف الصحيح"}
                {language === "french" && "Choisissez la bonne lettre"}
              </Text>
            </>
          )}
          {!isRunning && seconds === 0 && (
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <TouchableOpacity
                onPress={() => {
                  if (displayName === "") {
                    if (language === "english") {
                      alert("Please enter your name");
                    }
                    if (language === "arabic") {
                      alert("الرجاء إدخال اسمك");
                    }
                    if (language === "french") {
                      alert("Veuillez entrer votre nom");
                    }
                    return;
                  }
                  handleStart();
                }}
                style={[
                  {
                    backgroundColor: "white",
                    padding: 10,
                    margin: 8,
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 150,
                  },
                  tailwind.style([
                    "shadow-md",
                    "hover:shadow-lg",
                    "hover:bg-slate-400",
                    "bg-green-500",
                  ]),
                ]}
              >
                <Text
                  style={tailwind.style([
                    "text-base",
                    "text-white",
                    "font-bold",
                  ])}
                >
                  {language === "english" && "Start"}
                  {language === "arabic" && "بداية"}
                  {language === "french" && "Début"}
                </Text>
              </TouchableOpacity>
              <TextInput
                style={{
                  height: 40,
                  width: 150,
                  borderColor: "gray",
                  borderWidth: 1,
                  borderRadius: 5,
                  textAlign: "center",
                  marginTop: 10,
                  margin: 8,
                }}
                placeholder={
                  language === "english"
                    ? "Enter your name"
                    : language === "arabic"
                    ? "أدخل اسمك"
                    : "Entrez votre nom"
                }
                onChangeText={(text) => setdisplayName(text)}
                value={displayName}
              />
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={[
                    tailwind.style([
                      "text-base",
                      "text-black",
                      "font-semibold",
                      "mt-5",
                      "mb-5",
                      "mr-2",
                    ]),
                    { fontSize: normalize(22) },
                  ]}
                >
                  {language === "english" && "Language"}
                  {language === "arabic" && "لغة"}
                  {language === "french" && "Langue"}
                </Text>
                <select
                  value={language}
                  onChange={(e) => setlanguage(e.target.value)}
                  style={{
                    height: 30,
                    width: 125,
                    borderColor: "gray",
                    borderWidth: 1,
                    borderRadius: 5,
                    textAlign: "center",
                  }}
                >
                  <option value="english">English</option>
                  <option value="arabic">Arabic</option>
                  <option value="french">French</option>
                </select>
              </View>
              <Text
                style={[
                  tailwind.style([
                    "text-base",
                    "text-black",
                    "font-semibold",
                    "mt-5",
                    "mb-5",
                  ]),
                  { fontSize: normalize(22) },
                ]}
              >
                {language === "english" && "Learn the Assyrian Alphabet"}
                {language === "arabic" && "تعلم الأبجدية الآشورية"}
                {language === "french" && "Apprenez l'alphabet assyrien"}
              </Text>
              <TouchableOpacity
                onPress={() => setviewLeaderboardModal(true)}
                style={[
                  {
                    backgroundColor: "white",
                    padding: 10,
                    margin: 8,
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 150,
                  },
                  tailwind.style([
                    "shadow-md",
                    "hover:shadow-lg",
                    "hover:bg-slate-400",
                  ]),
                ]}
              >
                <Text
                  style={tailwind.style(["text-base", "text-black", "text-sm"])}
                >
                  {language === "english" && "View Leaderboard"}
                  {language === "arabic" && "عرض لوحة الزعامة"}
                  {language === "french" && "Voir le classement"}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          {isRunning && (
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                marginTop: 25,
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {answerOptions.map((option, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => CheckAnswer(option.letter)}
                    style={[
                      {
                        backgroundColor: "white",
                        padding: 10,
                        margin: 12,
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 90,
                      },
                      tailwind.style([
                        "shadow-md",
                        "hover:shadow-lg",
                        "hover:bg-slate-400",
                      ]),
                    ]}
                  >
                    <Text style={tailwind.style(["text-base"])}>
                      {option.letter}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
          {score === scrambledLetters.length && !isRunning && (
            <TouchableOpacity
              onPress={handleReset}
              style={[
                {
                  backgroundColor: "white",
                  padding: 10,
                  margin: 8,
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 150,
                },
                tailwind.style([
                  "shadow-md",
                  "hover:shadow-lg",
                  "hover:bg-slate-400",
                ]),
              ]}
            >
              <Text style={tailwind.style(["text-base"])}>Reset</Text>
            </TouchableOpacity>
          )}
        </View>
      )}
      <Modal
        animationType="slide"
        transparent={true}
        visible={viewLeaderboardModal}
        onRequestClose={() => {
          setviewLeaderboardModal(false);
        }}
      >
        <LeaderboardModal
          close={() => setviewLeaderboardModal(false)}
          language={language}
        />
      </Modal>
    </View>
  );
};

export default PracticeLetterFlashcards;
