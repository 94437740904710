import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCZ16aNVOjfYTbO6ydRZKA0SxlKcXj2Vr4",
  authDomain: "ninevehonline-7bdae.firebaseapp.com",
  projectId: "ninevehonline-7bdae",
  storageBucket: "ninevehonline-7bdae.appspot.com",
  messagingSenderId: "831214565950",
  appId: "1:831214565950:web:0de666187e707dcd226f6f",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const auth = firebase.auth();

export const db = firebase.firestore();

export const storage = firebase.storage();