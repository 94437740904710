import { Dimensions, Platform, PixelRatio } from "react-native";

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");

// Define constants for standard screen sizes
const STANDARD_SCREEN_WIDTH = 375; // iPhone 11 width
const STANDARD_SCREEN_HEIGHT = 812; // iPhone 11 height

// Calculate scale based on screen width
const widthScale = SCREEN_WIDTH / STANDARD_SCREEN_WIDTH;
const heightScale = SCREEN_HEIGHT / STANDARD_SCREEN_HEIGHT;
const scale = Math.min(widthScale, heightScale);

export function normalize(size) {
  const newSize = size * scale;
  const roundedSize = Math.round(PixelRatio.roundToNearestPixel(newSize));

  // Adjust size for different platforms
  if (Platform.OS === "ios") {
    return roundedSize;
  } else {
    return roundedSize - 2;
  }
}
