import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Modal,
  ImageBackground,
} from "react-native";
import React, { useEffect, useState } from "react";
import tailwind from "twrnc";
import { db } from "state/firebaseConfig";
import { normalize } from "components/normalize";
import { useHistory } from "react-router-dom";
import { Entypo } from "@expo/vector-icons";

const Index = () => {
  const prayerInAssyrian =
    "ܒܵܒܲܢ ܕܒܫܡܲܝܵܐ، ܦܵܐܹܫ ܡܩܘܼܕܫܵܐ ܫܸܡܘܼܟ݂، ܐܵܬܝܵܐ ܡܲܠܟܘܼܬܘܼܟ݂، ܗܵܘܹܐ ܨܸܒ݂ܝܵܢܘܼܟ݂، ܕܵܐܟ݂ܝܼ ܕܒܫܡܲܝܵܐ ܐܘܼܦ ܒܐܲܪܥܵܐ. ܗܲܒ݂݇ܠ ܠܲܢ ܠܲܚܡܵܐ ܕܣܘܼܢܩܵܢܲܢ ܐܸܕܝܘܿܡ ܝܵܘܡܵܐ. ܘܫܒ݂ܘܿܩ ܐܸܠܲܢ ܕܵܝܢܲܢ̈، ܕܵܐܟ݂ܝܼ ܕܐܘܼܦ ܐܲܚܢܲܢ ܫܒ݂ܝܼܩ ܠܲܢ ܠܐܵܢܝܼ ܕܕܵܝܢܵܢܹ̈ܐ ܝܢܵܐ ܐܸܠܲܢ. ܠܵܐ ܡܲܥܒܸܪܸܬ ܠܲܢ ܠܢܸܣܝܘܿܢܵܐ، ܐܸܠܵܐ ܦܲܨܝܼ ܠܲܢ ܡ̣ܢ ܒܝܼܫܵܐ، ܣܵܒܵܒ ܕܝܼܘܼܟ݂ ܝܼܠܵܗ̇ ܡܲܠܟܘܼܬܵܐ، ܘܚܲܝܠܵܐ، ܘܬܸܫܒܘܿܚܬܵܐ ܠܥܵܠܲܡ ܥܵܠܡܝܼܢ. ܐܵܡܹܝܢ.";
  const prayerInEnglish =
    "baban d'bshmaya, payish m-qudsha shimookh, atya malkootukh, haweh siwyanookh, dakhee d'bshmaya oop b-ar'a. hal lun lakhma d'sunqanan idyom yoma. oo-shwooq illan daynan, dakhee d-oop akhnun shwiq lun l-anee d'daynaneh na illan. la ma'birrit lan l'nisyona, illa pasee lan min beesha, sabab deeyukh eelah malkuta, oo-khayla, oo-tishbokhta l'alam almin. amen.";
  const prayerArrayAssyrian = prayerInAssyrian.split(" ");
  const prayerArrayEnglish = prayerInEnglish.split(" ");
  const [score, setscore] = useState(0);
  const [answerOptions, setanswerOptions] = useState([]);
  const [showCorrectAnswer, setshowCorrectAnswer] = useState(false);
  const [wrongLetterChosenIndex, setwrongLetterChosenIndex] = useState(null);
  const history = useHistory();
  const [language, setlanguage] = useState("assyrian");

  useEffect(() => {
    Start();
    console.log(
      "Assyrian Length: ",
      prayerArrayAssyrian.length,
      " English Length: ",
      prayerArrayEnglish.length
    );
    console.log("Assyrian: ", prayerArrayAssyrian);
    console.log("English: ", prayerArrayEnglish);
  }, []); // Initialize scrambled letters on mount

  const Start = () => {
    const newAnswerOptions = [
      {
        assyrian: prayerArrayAssyrian[0],
        english: prayerArrayEnglish[0],
      },
    ];

    while (newAnswerOptions.length < 4) {
      const randomIndex = Math.floor(
        Math.random() * prayerArrayAssyrian.length
      );
      const doesExist = newAnswerOptions.some(
        (option) => option.assyrian === prayerArrayAssyrian[randomIndex]
      );
      if (!doesExist) {
        newAnswerOptions.push({
          assyrian: prayerArrayAssyrian[randomIndex],
          english: prayerArrayEnglish[randomIndex],
        });
      }
    }
    setanswerOptions(newAnswerOptions.sort(() => Math.random() - 0.5));
  };

  const CheckAnswer = (word) => {
    setwrongLetterChosenIndex(null);
    setshowCorrectAnswer(false);
    if (prayerArrayAssyrian[score] === word) {
      setscore(score + 1);

      const newAnswerOptions = [
        {
          assyrian: prayerArrayAssyrian[score + 1],
          english: prayerArrayEnglish[score + 1],
        },
      ];

      while (newAnswerOptions.length < 4) {
        const randomIndex = Math.floor(
          Math.random() * prayerArrayAssyrian.length
        );
        const doesExist = newAnswerOptions.some(
          (option) => option.assyrian === prayerArrayAssyrian[randomIndex]
        );
        if (!doesExist) {
          newAnswerOptions.push({
            assyrian: prayerArrayAssyrian[randomIndex],
            english: prayerArrayEnglish[randomIndex],
          });
        }
      }
      setanswerOptions(newAnswerOptions.sort(() => Math.random() - 0.5));
    } else {
      setshowCorrectAnswer(true);
      const wrongLetterIndex = answerOptions.findIndex(
        (option) => option.assyrian === word
      );
      setwrongLetterChosenIndex(wrongLetterIndex);
    }
  };

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={[
          {
            padding: 20,
            justifyContent: "center",
            alignItems: "center",
          },
          tailwind.style([
            "shadow-lg",
            "rounded-lg",
            "bg-slate-300",
            "w-10/12",
            "h-11/12",
          ]),
        ]}
      >
        {score === prayerArrayAssyrian.length ? (
          <>
            <Text
              style={[
                tailwind.style([
                  "text-black",
                  "font-bold",
                  "mt-10",
                  "mb-5",
                  "text-center",
                ]),
                { fontSize: normalize(26) },
              ]}
            >
              Congratulations! You have completed the game
            </Text>
            <TouchableOpacity
              style={tailwind.style("bg-blue-500 rounded-lg p-4 mb-4")}
              onPress={() => {
                setscore(0);
                Start();
              }}
            >
              <Text style={tailwind.style("text-white text-lg text-center")}>
                Restart
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={tailwind.style("bg-blue-500 rounded-lg p-4")}
              onPress={() => history.push("/")}
            >
              <Text style={tailwind.style("text-white text-lg text-center")}>
                Go Back
              </Text>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <TouchableOpacity
              onPress={() => history.push("/")}
              style={tailwind.style("absolute top-5 left-7")}
            >
              <Entypo name="chevron-with-circle-left" size={35} color="black" />
            </TouchableOpacity>
            <Text
              style={[
                tailwind.style([
                  "text-black",
                  "font-bold",
                  "absolute",
                  "top-7",
                  "right-7",
                ]),
                { fontSize: normalize(22) },
              ]}
            >
              Word: {score + 1}/{prayerArrayAssyrian.length}
            </Text>
            <Text
              style={[
                tailwind.style(["text-black", "font-bold"]),
                {
                  fontSize: normalize(26),
                  marginTop: normalize(50),
                  marginBottom: normalize(20),
                },
              ]}
            >
              {score === 0
                ? "What Is The First Word?"
                : "What Is The Next Word?"}
            </Text>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                marginTop: 25,
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {answerOptions.map((option, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => CheckAnswer(option.assyrian)}
                    style={[
                      {
                        backgroundColor: "white",
                        padding: 10,
                        margin: 12,
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: 120,
                        minHeight: 60,
                      },
                      tailwind.style([
                        "shadow-md",
                        "hover:shadow-lg",
                        "hover:bg-slate-400",
                      ]),
                      showCorrectAnswer &&
                        option.assyrian === prayerArrayAssyrian[score] && {
                          backgroundColor: "green",
                        },
                      showCorrectAnswer &&
                        index === wrongLetterChosenIndex && {
                          backgroundColor: "red",
                        },
                    ]}
                  >
                    <Text
                      style={[
                        language === "assyrian"
                          ? { fontSize: 30, fontFamily: "AssyrianFont" }
                          : { fontSize: 20 },
                      ]}
                    >
                      {language === "assyrian"
                        ? option.assyrian
                        : option.english}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </>
        )}
        {score !== prayerArrayAssyrian.length && (
          <TouchableOpacity
            style={tailwind.style(
              "bg-blue-500 rounded-lg mt-7 justify-center items-center p-4"
            )}
            onPress={() =>
              setlanguage(language === "assyrian" ? "english" : "assyrian")
            }
          >
            <Text style={tailwind.style("text-white text-lg text-center")}>
              {language === "assyrian"
                ? "Switch to English"
                : "Switch to Assyrian"}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default Index;
