import { Text, TextInput, TouchableOpacity, View } from "react-native";
import React from "react";
import tailwind, { style as tw } from "twrnc";
import { normalize } from "components/normalize";
import { useHistory } from "react-router-dom";
import { Entypo } from "@expo/vector-icons";

const WriteAssyrianTextInput = () => {
  const history = useHistory();

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={[
          {
            padding: 20,
            justifyContent: "center",
            alignItems: "center",
          },
          tailwind.style([
            "shadow-lg",
            "rounded-lg",
            "bg-slate-300",
            "w-10/12",
            "h-11/12",
          ]),
        ]}
      >
        <TouchableOpacity
          onPress={() => history.push("/")}
          style={tailwind.style("absolute top-5 left-7")}
        >
          <Entypo name="chevron-with-circle-left" size={35} color="black" />
        </TouchableOpacity>
        <Text
          style={tailwind.style([
            "text-2xl",
            "font-bold",
            "text-black",
            "text-center",
          ])}
        >
          Assyrian Font Text Input
        </Text>
        <TextInput
          style={{
            width: "100%",
            height: "70%",
            fontFamily: "AssyrianFont",
            fontSize: 40,
            marginTop: 20,
            backgroundColor: "white",
            padding: 10,
            textAlign: "right",
          }}
          multiline={true}
          placeholder="ܟܬ̤ܒ݂ ܐ̈ܟ݂̈ܐ"
        />
      </View>
    </View>
  );
};

export default WriteAssyrianTextInput;
