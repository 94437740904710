import React, {  useEffect } from 'react';
import {  Route, Switch } from 'react-router-dom';
import HomeScreen from 'screens/authed/HomeScreen';

const AuthRoute = () => {
    return (
        <Switch>
            <Route path="/" component={HomeScreen} />
        </Switch>
    )
}

export default AuthRoute