import { Text, TouchableOpacity, View } from "react-native";
import React from "react";
import tailwind, { style as tw } from "twrnc";
import { normalize } from "components/normalize";
import { useHistory } from "react-router-dom";
import { Entypo } from "@expo/vector-icons";

const LearnSpecialLetters = () => {
  const history = useHistory();

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={[
          {
            padding: 20,
            justifyContent: "center",
            alignItems: "center",
          },
          tailwind.style([
            "shadow-lg",
            "rounded-lg",
            "bg-slate-300",
            "w-10/12",
            "h-11/12",
          ]),
        ]}
      >
        <TouchableOpacity
          onPress={() => history.push("/learn-vowels-home")}
          style={tailwind.style("absolute top-5 left-7")}
        >
          <Entypo name="chevron-with-circle-left" size={35} color="black" />
        </TouchableOpacity>
        <Text
          style={tailwind.style([
            "text-2xl",
            "font-bold",
            "text-black",
            "text-center",
          ])}
        >
          Please Choose What Stage You Are At
        </Text>
        <View style={tw("mt-20")}>
          <TouchableOpacity
            style={tw("bg-blue-500 rounded-lg justify-center items-center p-4")}
            onPress={() => history.push("/learn-vowels")}
          >
            <Text style={tw("text-white text-lg text-center")}>
              Learn Vowels
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={tw(
              "bg-blue-500 rounded-lg mt-7 justify-center items-center p-4"
            )}
            onPress={() => history.push("/practice-vowels")}
          >
            <Text style={tw("text-white text-lg text-center")}>
              Practice Vowels
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={tw(
              "bg-blue-500 rounded-lg mt-7 justify-center items-center p-4"
            )}
            onPress={() => history.push("/learn-special-letters")}
          >
            <Text style={tw("text-white text-lg text-center")}>
              Learn Special Letters
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={tw(
              "bg-blue-500 rounded-lg mt-7 justify-center items-center p-4"
            )}
            onPress={() => history.push("/practice-special-letters")}
          >
            <Text style={tw("text-white text-lg text-center")}>
              Practice Special Characters
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default LearnSpecialLetters;
