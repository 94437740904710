import { View, Text, Image, TouchableOpacity, TextInput } from "react-native";
import React, { useEffect, useState } from "react";
import tailwind from "twrnc";
import { db } from "state/firebaseConfig";
import { normalize } from "components/normalize";

const LeaderboardModal = ({ close, language }) => {
  const [currentLeaderboard, setcurrentLeaderboard] = useState([]);

  useEffect(() => {
    db.collection("public")
      .doc("leaderboard")
      .get()
      .then((doc) => {
        if (doc.exists) {
          setcurrentLeaderboard(doc.data().users);
        }
      });
  }, []);

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={[
          {
            padding: 20,
            justifyContent: "center",
            alignItems: "center",
          },
          tailwind.style([
            "shadow-lg",
            "rounded-lg",
            "bg-slate-300",
            "w-10/12",
            "h-11/12",
          ]),
        ]}
      >
        <>
          <Text
            style={[
              tailwind.style(["text-black", "font-bold", "mt-10", "mb-5"]),
              { fontSize: normalize(26) },
            ]}
          >
            {language === "english" && "These are the top 3 players!"}
            {language === "arabic" && "هؤلاء هم أفضل 3 لاعبين!"}
            {language === "french" && "Voici les 3 meilleurs joueurs!"}
          </Text>
          <Text
            style={tailwind.style([
              "text-black",
              "font-medium",
              "text-lg",
              "mt-5",
              "mb-5",
            ])}
          >
            {language === "english" && "Leaderboard: "}
            {language === "arabic" && "لوحة الزعامة: "}
            {language === "french" && "Classement: "}
          </Text>
          {currentLeaderboard.map((user, index) => {
            return (
              <Text
                key={index}
                style={[
                  tailwind.style(["text-black", "font-medium", "mt-5", "mb-5"]),
                  { fontSize: normalize(22) },
                ]}
              >
                {index + 1}. {user.name} - {user.time}
                {language === "english" && " seconds"}
                {language === "arabic" && " ثواني"}
                {language === "french" && " secondes"}
              </Text>
            );
          })}
        </>

        <TouchableOpacity
          onPress={close}
          style={[
            {
              backgroundColor: "white",
              padding: 10,
              margin: 8,
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
              width: 150,
            },
            tailwind.style([
              "shadow-md",
              "hover:shadow-lg",
              "hover:bg-slate-400",
            ]),
          ]}
        >
          <Text style={tailwind.style(["text-base"])}>Back</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LeaderboardModal;
