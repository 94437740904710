import React from "react";
import "react-native-gesture-handler";
import RouteManager from "navigation/RouteManager";
import { useFonts } from "expo-font";

//Do this before syncing git config --global core.editor "code --wait"
//

const App = () => {
  const [fontsLoaded] = useFonts({
    AssyrianFont: require("assets/estre.ttf"),
  });
  return <RouteManager />;
};

export default App;
