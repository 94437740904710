import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Modal,
  ImageBackground,
} from "react-native";
import React, { useEffect, useState } from "react";
import tailwind from "twrnc";
import { db } from "state/firebaseConfig";
import { normalize } from "components/normalize";
import { useHistory } from "react-router-dom";
import { Entypo } from "@expo/vector-icons";

const Index = () => {
  const letters = [
    {
      letter: "Alap",
      image: require("assets/letters/alap.png"),
    },
    {
      letter: "Bet",
      image: require("assets/letters/bet.png"),
    },
    {
      letter: "Gamal",
      image: require("assets/letters/gamal.png"),
    },
    {
      letter: "Dalat",
      image: require("assets/letters/dalat.png"),
    },
    {
      letter: "Heh",
      image: require("assets/letters/heh.png"),
    },
    {
      letter: "Waw",
      image: require("assets/letters/waw.png"),
    },
    {
      letter: "Zain",
      image: require("assets/letters/zain.png"),
    },
    {
      letter: "Khet",
      image: require("assets/letters/khet.png"),
    },
    {
      letter: "Teth",
      image: require("assets/letters/teth.png"),
    },
    {
      letter: "Yodh",
      image: require("assets/letters/yodh.png"),
    },
    {
      letter: "Kap",
      image: require("assets/letters/kap.png"),
    },
    {
      letter: "Lamadh",
      image: require("assets/letters/lamadh.png"),
    },
    {
      letter: "Meem",
      image: require("assets/letters/meem.png"),
    },
    {
      letter: "Noon",
      image: require("assets/letters/noon.png"),
    },
    {
      letter: "Simkat",
      image: require("assets/letters/simkat.png"),
    },
    {
      letter: "Aih",
      image: require("assets/letters/aih.png"),
    },
    {
      letter: "Peh",
      image: require("assets/letters/peh.png"),
    },
    {
      letter: "Sadeh",
      image: require("assets/letters/sadeh.png"),
    },
    {
      letter: "Qop",
      image: require("assets/letters/qop.png"),
    },
    {
      letter: "Resh",
      image: require("assets/letters/resh.png"),
    },
    {
      letter: "Sheen",
      image: require("assets/letters/sheen.png"),
    },
    {
      letter: "Taw",
      image: require("assets/letters/taw.png"),
    },
  ];

  const [score, setscore] = useState(0);
  const [answerOptions, setanswerOptions] = useState([]);
  const [showCorrectAnswer, setshowCorrectAnswer] = useState(false);
  const [wrongLetterChosenIndex, setwrongLetterChosenIndex] = useState(null);
  const history = useHistory();

  useEffect(() => {
    Start();
  }, []); // Initialize scrambled letters on mount

  const Start = () => {
    const newAnswerOptions = [
      {
        letter: letters[0].letter,
        image: letters[0].image,
      },
    ];

    while (newAnswerOptions.length < 4) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      const doesExist = newAnswerOptions.some(
        (option) => option.letter === letters[randomIndex].letter
      );
      if (!doesExist) {
        newAnswerOptions.push({
          letter: letters[randomIndex].letter,
          image: letters[randomIndex].image,
        });
      }
    }
    setanswerOptions(newAnswerOptions.sort(() => Math.random() - 0.5));
  };

  const CheckAnswer = (letter) => {
    setwrongLetterChosenIndex(null);
    setshowCorrectAnswer(false);
    if (letters[score].letter === letter) {
      setscore(score + 1);

      const newAnswerOptions = [
        {
          letter: letters[score + 1].letter,
          image: letters[score + 1].image,
        },
      ];

      while (newAnswerOptions.length < 4) {
        const randomIndex = Math.floor(Math.random() * letters.length);
        const doesExist = newAnswerOptions.some(
          (option) => option.letter === letters[randomIndex].letter
        );
        if (!doesExist) {
          newAnswerOptions.push({
            letter: letters[randomIndex].letter,
            image: letters[randomIndex].image,
          });
        }
      }
      setanswerOptions(newAnswerOptions.sort(() => Math.random() - 0.5));
    } else {
      setshowCorrectAnswer(true);
      const wrongLetterIndex = answerOptions.findIndex(
        (option) => option.letter === letter
      );
      setwrongLetterChosenIndex(wrongLetterIndex);
    }
  };

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={[
          {
            padding: 20,
            justifyContent: "center",
            alignItems: "center",
          },
          tailwind.style([
            "shadow-lg",
            "rounded-lg",
            "bg-slate-300",
            "w-10/12",
            "h-11/12",
          ]),
        ]}
      >
        {score === letters.length ? (
          <>
            <Text
              style={[
                tailwind.style([
                  "text-black",
                  "font-bold",
                  "mt-10",
                  "mb-5",
                  "text-center",
                ]),
                { fontSize: normalize(26) },
              ]}
            >
              Congratulations! You have completed the game
            </Text>
            <TouchableOpacity
              style={tailwind.style("bg-blue-500 rounded-lg p-4 mb-4")}
              onPress={() => {
                setscore(0);
                Start();
              }}
            >
              <Text style={tailwind.style("text-white text-lg text-center")}>
                Restart
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={tailwind.style("bg-blue-500 rounded-lg p-4")}
              onPress={() => history.push("/")}
            >
              <Text style={tailwind.style("text-white text-lg text-center")}>
                Go Back
              </Text>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <TouchableOpacity
              onPress={() => history.push("/")}
              style={tailwind.style("absolute top-5 left-7")}
            >
              <Entypo name="chevron-with-circle-left" size={35} color="black" />
            </TouchableOpacity>
            <Text
              style={[
                tailwind.style([
                  "text-black",
                  "font-bold",
                  "absolute",
                  "top-7",
                  "right-7",
                ]),
                { fontSize: normalize(22) },
              ]}
            >
              Letter: {score + 1}/{letters.length}
            </Text>
            <Text
              style={[
                tailwind.style(["text-black", "font-bold"]),
                {
                  fontSize: normalize(26),
                  marginTop: normalize(50),
                  marginBottom: normalize(20),
                },
              ]}
            >
              {score === 0
                ? "What Is The First Letter?"
                : "What Is The Next Letter?"}
            </Text>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                marginTop: 25,
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {answerOptions.map((option, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => CheckAnswer(option.letter)}
                    style={[
                      {
                        backgroundColor: "white",
                        padding: 10,
                        margin: 12,
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 90,
                      },
                      tailwind.style([
                        "shadow-md",
                        "hover:shadow-lg",
                        "hover:bg-slate-400",
                      ]),
                      showCorrectAnswer &&
                        option.letter === letters[score].letter && {
                          backgroundColor: "green",
                        },
                      showCorrectAnswer &&
                        index === wrongLetterChosenIndex && {
                          backgroundColor: "red",
                        },
                    ]}
                  >
                    <Image
                      source={option.image}
                      style={{
                        width: 50,
                        height: 50,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                    <Text style={tailwind.style(["text-base"])}>
                      {option.letter}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </>
        )}
      </View>
    </View>
  );
};

export default Index;
