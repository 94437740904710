import { Text, TouchableOpacity, View } from "react-native";
import React, { useState } from "react";
import tailwind, { style as tw } from "twrnc";
import { normalize } from "components/normalize";
import { useHistory } from "react-router-dom";
import { Entypo } from "@expo/vector-icons";

const LearnVowels = () => {
  const history = useHistory();
  const [stage, setstage] = useState(0);
  const vowels = [
    {
      vowel: "Zqapa",
      look: "ܐܵ",
      details:
        'ZQAPA (pronounced "sqapa") - "AH" (as in "Father") placing two dots in an angle above a letter changes it to an "AH" sound.',
    },
    {
      vowel: "Ptakha",
      look: "ܐܲ",
      details:
        'PTHAKHA - "UH" (as in "Verbal") placing a dot above and below the letter changes it to an "UH" sound.',
    },
    {
      vowel: "Zlama Qashya",
      look: "ܐܹ",
      details:
        'ZLAMA QASHYA - "EH" (as in "Bear") placing two dots underneath a letter changes it to and "EH" sound.',
    },
    {
      vowel: "Zlama Psheeqa",
      look: "ܐܸ",
      details:
        'ZLAMA PSHEEQA - "IH" (as in "sit") placing two dots horizontaly below the letter changes it to an "IH" sound.',
    },
    {
      vowel: "Waw Rwakha",
      look: "ܘ̇",
      details:
        'RWAKHA - "OH" (as is "Phone") placing a dot above the Waw letter changes it to an "OH" sound.',
    },
    {
      vowel: "Waw Rwasa",
      look: "ܘ̣",
      details:
        'RWASA - "OO" (as in "Moon") placing a dot below the Waw letter changes it to the "OO" sound.',
    },
    {
      vowel: "Yodh Khwasa",
      look: "ܝܼ",
      details:
        'KHWASA - "EE" (as in "Bee) placing a dot below the Yodh letter changes it to the "EE" sound.',
    },
  ];

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={[
          {
            padding: 20,
            justifyContent: "space-evenly",
            alignItems: "center",
          },
          tailwind.style([
            "shadow-lg",
            "rounded-lg",
            "bg-slate-300",
            "w-10/12",
            "h-11/12",
          ]),
        ]}
      >
        <TouchableOpacity
          onPress={() => history.push("/learn-vowels-home")}
          style={tailwind.style("absolute top-5 left-7")}
        >
          <Entypo name="chevron-with-circle-left" size={35} color="black" />
        </TouchableOpacity>
        <Text
          style={tailwind.style([
            "text-2xl",
            "font-bold",
            "text-black",
            "text-center",
          ])}
        >
          Learn Vowels
        </Text>
        <View
          style={[
            tw("mt-20"),
            {
              justifyContent: "space-between",
                alignItems: "center",
              height: '78%'
            },
          ]}
        >
          <Text
            style={tailwind.style([
              "text-2xl",
              "font-bold",
              "text-black",
                "text-center",
              'mb-8'
            ])}
          >
            {vowels[stage].vowel}
          </Text>
          <Text style={[{ fontFamily: "AssyrianFont", fontSize: 60 }]}>
            {vowels[stage].look}
          </Text>
          <Text
            style={tailwind.style(["text-2xl", "text-black", "text-center"])}
          >
            {vowels[stage].details}
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TouchableOpacity
              style={tw(
                "bg-blue-500 rounded-lg justify-center items-center p-4"
              )}
              onPress={() => setstage(stage - 1)}
            >
              <Entypo name="chevron-left" size={24} color="white" />
            </TouchableOpacity>
            <Text
              style={tailwind.style([
                "text-2xl",
                "font-bold",
                "text-black",
                "text-center",
              ])}
            >
              {stage + 1} / {vowels.length}
            </Text>
            {stage === vowels.length - 1 ? (
              <TouchableOpacity
                style={tw(
                  "bg-blue-500 rounded-lg justify-center items-center p-4"
                )}
                onPress={() => history.push("/practice-vowels")}
              >
                <Text style={tw("text-white text-lg text-center")}>
                  Practice Vowels
                </Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={tw(
                  "bg-blue-500 rounded-lg justify-center items-center p-4"
                )}
                onPress={() => setstage(stage + 1)}
              >
                <Entypo name="chevron-right" size={24} color="white" />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default LearnVowels;
