import React, { useEffect, useState } from "react";
import { setUserState, userState } from "state/state";
import { auth, db } from "state/firebaseConfig";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthRoute from "./authed/AuthRoute";
import NonAuthRoute from "./non-authed/NonAuthRoute";

const RouteManager = () => {
  const userS = userState.use();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const unsubscribeAuthStateChanged = auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            setUserState({
              name: doc.data().name,
            });
            setloading(false);
          })
          .catch(() => console.log("Error has occured with db"));
      } else {
        setUserState(null);
        setloading(false);
      }
    });

    return () => {
      unsubscribeAuthStateChanged();
    };
  }, []);

  return (
    <Router>
      <Switch>
        {userS && <Route path="/" component={AuthRoute} />}
        {!userS && !loading && <Route path="/" component={NonAuthRoute} />}
      </Switch>
    </Router>
  );
};

export default RouteManager;
